const motifSource = require('./../data/motif.json');
const sldsIconColors = require('./../data/slds-icon-colors.json');

const extractIconName = (img) => {
  const imagePaths = img && img['_text'] && img['_text'].split('/');
  if (imagePaths && imagePaths.length) {
    const imgText = img['_text'];
    const isStandard = imgText.includes('Standard');
    const isAction = imgText.includes('Action');
    const imageName = imagePaths[imagePaths.length - 1];
    if (imageName.includes('$')) {
      return null;
    } else {
      let iconType = '';
      if (isStandard) {
        return imageName + ' [Standard]';
      } else if (isAction) {
        return imageName + ' [Action]';
      } else {
        return imageName;
      }
    }
  }
  return null;
};

const lookUpSldsIconColors = (targetColor) => {
  const standardColors = sldsIconColors['standard'];
  const actionColors = sldsIconColors['action'];
  let lookUpValue = null;

  Object.keys(standardColors)
    .forEach(colorKey => {
      if (standardColors[colorKey].includes(targetColor)) {
        lookUpValue = colorKey;
      }
    });

  if (!lookUpValue) {
    Object.keys(actionColors)
    .forEach(colorKey => {
      if (actionColors[colorKey].includes(targetColor)) {
        lookUpValue = colorKey;
      }
    });
  }

  return lookUpValue;
};

const getSldsIconColor = (primaryPaletteNest, primaryPalette) => {
  if (primaryPaletteNest) {
    return lookUpSldsIconColors(primaryPaletteNest);
  } else if (primaryPalette) {
    return lookUpSldsIconColors(primaryPalette);
  } else {
    return null;
  }
};

const readMotifJson = (motifJson = {}) => {
  const motifData = motifJson['motif'];
  if (!motifData || !motifData['section']) {
    return;
  }

  const entities = [];

  const sections = motifJson['motif'].section;
  
  sections.forEach(section => {
    const sectionName = section['_attributes'].name;
    const params = section['param'];
    const images = section['image'];
    const motifIcons = section['icon'];
    
    // Exported attributes
    let primaryPalette = null;
    let primaryPaletteNest = null;
    let secondaryPalette = null;
    let tertiaryPalette = null;
    let icons = [];
    let iconsToRender = [];

    if (params) {
      if (Array.isArray(params)) {
        params.forEach(param => {
          const paramName = param['_attributes'].name;
          if (paramName === 'primaryPalette') {
            primaryPalette = param['_text'].toUpperCase().trim();
          }
          
          if (paramName === 'primaryPalette_nest') {
            primaryPaletteNest = param['_text'].toUpperCase().trim();
          }
          
          if (paramName === 'secondaryPalette') {
            secondaryPalette = param['_text'].toUpperCase();
          }
          
          if (paramName === 'tertiaryPalette') {
            tertiaryPalette = param['_text'].toUpperCase();
          }
        });
      } else {
        const paramName = params['_attributes'].name;
        if (paramName === 'primaryPalette') {
          primaryPalette = params['_text'].toUpperCase();
        }
        
        if (paramName === 'primaryPalette_nest') {
          primaryPaletteNest = params['_text'].toUpperCase();
        }
      }
    }

    if (images) {
      const iconSet = new Set();
      if (Array.isArray(images)) {
        images.forEach(image => {
          const iconName = extractIconName(image);
          if (iconName) {
            iconSet.add(iconName);
          }
        });
      } else {
        const iconName = extractIconName(images);
        if (iconName) {
          iconSet.add(iconName);
        }
      }
      icons = Array.from(iconSet);

      iconsToRender = icons
        .filter(icon => (icon.includes('[Standard]') || icon.includes('[Action]')) && icon.includes('120.png'))
        .slice(0, 2);
    }

    if (motifIcons) {
      const iconSet = new Set();
      if (Array.isArray(motifIcons)) {
        motifIcons.forEach(icon => {
          const iconName = extractIconName(icon);
          if (iconName) {
            iconSet.add(iconName);
          }
        });
      } else {
        const iconName = extractIconName(motifIcons);
        if (iconName) {
          iconSet.add(iconName);
        }
      }
      icons = Array.from(iconSet).concat(icons);
    }

    const sldsColor = getSldsIconColor(primaryPaletteNest, primaryPalette);
    entities.push({
      sectionName,
      primaryPalette,
      primaryPaletteNest,
      secondaryPalette,
      tertiaryPalette,
      sldsColor,
      icons,
      iconsToRender
    });
  });

  return entities;
};

export const getMotifData = () => {
  return readMotifJson(motifSource);
};
