import { getMotifData } from './motif.js';
import './../styles/main.scss';

let motifData = null;

const formatIcons = (icons) => {
  let html = `<ul class="motif-icons">`;
  icons.forEach(icon => {
    html += `<li>${icon}</li>`
  });
  html += '</ul>';
  return html;
};

const renderIcons = (icons, oldIconColor, newIconColor) => {
  let html = `<ul class="motif-icons-rendered">`;
  icons.forEach(icon => {
    const iconName = icon.replace('[Standard]', '').replace('[Action]', '').trim();
    const iconType = icon.includes('[Standard]') ? 'standard' : 'action';
    html += `<li>
    <img src="https://gus.my.salesforce.com/img/icon/t4v35/${iconType}/${iconName}"
      onerror="this.onerror=null;this.src='https://gus.my.salesforce.com/img/icon/t4v32/${iconType}/${iconName}';"
      style="background-color: #${oldIconColor}"> &rarr;
    <img src="https://gus.my.salesforce.com/img/icon/t4v35/${iconType}/${iconName}"
      onerror="this.onerror=null;this.src='https://gus.my.salesforce.com/img/icon/t4v32/${iconType}/${iconName}';" 
      style="background-color: #${newIconColor}">
    </li>`
  });
  html += '</ul>';
  return html;
};

const formatAndRenderIcons = (tableData) => {
  const icons = tableData.iconsToRender;
  const oldIconColor = tableData.primaryPaletteNest || tableData.primaryPalette;
  const newIconColor = tableData.sldsColor;
  let htmlOutput = formatIcons(icons);
  htmlOutput += renderIcons(icons, oldIconColor, newIconColor);
  return htmlOutput;
};

const doIconsContain = (icons, iconType) => {
  return icons && icons.find(icon => icon.includes(iconType));
};

const appendRowToMotifTable = (tableData) => {
  const motifTableBody = document.querySelector('.motif-data-tbody');
  
  let newRow = motifTableBody.insertRow();
  let cell0 = newRow.insertCell(0); // rowNum
  let cell1 = newRow.insertCell(1); // Section Name
  let cell2 = newRow.insertCell(2); // Primary Palette
  let cell3 = newRow.insertCell(3); // Primary Palette Nest
  let cell4 = newRow.insertCell(4); // New Color
  let cell5 = newRow.insertCell(5); // Icons
  let cell6 = newRow.insertCell(6); // Icons To Render

  cell0.innerHTML = tableData.rowNum || 1;
  cell1.innerHTML = tableData.sectionName || 'NA';
  
  if (tableData.primaryPalette) {
    cell2.style.backgroundColor = `#${tableData.primaryPalette}`;
    cell2.style.color = `#FFFFFF`;
    cell2.innerHTML = `#${tableData.primaryPalette}`;
  } else {
    cell2.innerHTML = 'NA';
  }
  
  if (tableData.primaryPaletteNest) {
    cell3.style.backgroundColor = `#${tableData.primaryPaletteNest}`;
    cell3.style.color = `#FFFFFF`;
    cell3.innerHTML = `#${tableData.primaryPaletteNest}`;
  } else {
    cell3.innerHTML = 'NA';
  }

  if (tableData.sldsColor) {
    cell4.style.backgroundColor = `#${tableData.sldsColor}`;
    cell4.style.color = `#FFFFFF`;
    cell4.innerHTML = `#${tableData.sldsColor}`;
  } else {
    cell4.innerHTML = 'NA';
  }
  
  if (tableData.icons) {
    cell5.innerHTML = formatIcons(tableData.icons);
  } else {
    cell5.innerHTML = 'NA';
  }

  if (tableData.iconsToRender) {
    cell6.innerHTML = tableData.iconsToRender.length ? formatAndRenderIcons(tableData) : 'NA';
  }
};

const populateMotifData = (motifArr) => {
  document.querySelector('.motif-data-tbody').innerHTML = "";
  motifArr.forEach((data, index) => {
    data['rowNum'] = ++index;
    appendRowToMotifTable(data);
  });
};

const initializeRadioFilters = () => {
  const radioFilters = document.filterForm.filter;
  let previousSelOption = null;

  for(let i = 0; i < radioFilters.length; i++) {
    radioFilters[i].addEventListener('change', function() {
      if (this !== previousSelOption) {
        previousSelOption = this;
      }  

      if (!motifData) {
        motifData = getMotifData();
      }

      if (this.value === 'valid-pp-nest') {
        const filteredData = [];
        motifData.forEach((data, index) => {
          if (data['primaryPaletteNest'] || data['primaryPalette']) {
            filteredData.push(data);
          }
        });
        populateMotifData(filteredData);
      } else if (this.value === 'invalid-pp-nest') {
        const filteredData = [];
        motifData.forEach((data, index) => {
          if (!data['primaryPaletteNest']) {
            filteredData.push(data);
          }
        });
        populateMotifData(filteredData);
      } else if (this.value === 'invalid-pp-and-pp-nest') {
        const filteredData = [];
        motifData.forEach((data, index) => {
          if (!data['primaryPaletteNest'] && !data['primaryPalette']) {
            filteredData.push(data);
          }
        });
        populateMotifData(filteredData);
      } else if (this.value === 'invalid-new-pp-nest') {
        const filteredData = [];
        motifData.forEach((data, index) => {
          const sectionName = data['sectionName'];
          const hasPpOrPPNest = data['primaryPaletteNest'] || data['primaryPalette'];
          const isSldsColorEmpty = data['sldsColor'] === null;
          const isCustomEntity = sectionName && sectionName.length > 6
            && sectionName.startsWith('Custom') && !isNaN(sectionName.charAt(6));

          if (hasPpOrPPNest && isSldsColorEmpty && !isCustomEntity) {
            filteredData.push(data);
          }
        });
        populateMotifData(filteredData);
      } else if (this.value === 'standard-or-action-icons') {
        const filteredData = [];
        motifData.forEach((data, index) => {
          if (doIconsContain(data['icons'], '[Standard]') || doIconsContain(data['icons'], '[Action]')) {
            filteredData.push(data);
          }
        });
        populateMotifData(filteredData);
      } else if (this.value === 'only-action-icons') {
        const filteredData = [];
        motifData.forEach((data, index) => {
          if (doIconsContain(data['icons'], '[Action]') && !doIconsContain(data['icons'], '[Standard]')) {
            filteredData.push(data);
          }
        });
        populateMotifData(filteredData);
      } else if (this.value === 'both-standard-action-icons') {
        const filteredData = [];
        motifData.forEach((data, index) => {
          if (doIconsContain(data['icons'], '[Standard]') && doIconsContain(data['icons'], '[Action]')) {
            filteredData.push(data);
          }
        });
        populateMotifData(filteredData);
      } else if (this.value === 'no-standard-and-action-icons') {
        const filteredData = [];
        motifData.forEach((data, index) => {
          if (!doIconsContain(data['icons'], '[Standard]') && !doIconsContain(data['icons'], '[Action]')) {
            filteredData.push(data);
          }
        });
        populateMotifData(filteredData);
      } else {
        populateMotifData(motifData);
      }
      
    });
  }
};

const _init = () => {
  initializeRadioFilters();
  motifData = getMotifData();
  populateMotifData(motifData);
};


_init();
